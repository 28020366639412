import { BookingSoftwareOption, HeardAboutOption, Scale, ScaleQuantity } from './interfaces'

export const LOADING_SIGNUP = 'loading-signup'
export const BLACK_SCALE: Scale = {
  id: 'black',
  colorHex: '#212121',
  label: 'Black',
  imgUrl: 'https://storage.googleapis.com/salonscale-prod-webapp-assets/images/Savvy-Black.jpg',
}

export const BLACK_MINI_SCALE: Scale = {
  // todo: update this with the id used in chargebee once it's created
  id: 'blackmini',
  colorHex: '#212121',
  label: 'Mini',
  // todo: update this with the miniscale image when we get it
  imgUrl: 'https://storage.googleapis.com/salonscale-prod-webapp-assets/images/Savvy-Black.jpg',
}
export const MIDNIGHT_SCALE: Scale = {
  id: 'midnight',
  colorHex: '#243F70',
  label: 'Midnight',
  imgUrl: 'https://storage.googleapis.com/salonscale-prod-webapp-assets/images/Magnificent-Midnight.jpg',
}
export const LAVENDER_SCALE: Scale = {
  id: 'lavender',
  colorHex: '#D3D5F4',
  label: 'Lavender',
  imgUrl: 'https://storage.googleapis.com/salonscale-prod-webapp-assets/images/Lovely-Lavender.jpg',
}
export const PEACH_SCALE: Scale = {
  id: 'peach',
  colorHex: '#F8DED1',
  label: 'Peach',
  imgUrl: 'https://storage.googleapis.com/salonscale-prod-webapp-assets/images/Just-Peachy.jpg',
}
export const LINEN_SCALE: Scale = {
  id: 'linen',
  colorHex: '#FFE5C5',
  label: 'Linen',
  imgUrl: 'https://storage.googleapis.com/salonscale-prod-webapp-assets/images/Scale-Linen.png',
}

export const ScaleMap = {
  black: BLACK_SCALE,
  midnight: MIDNIGHT_SCALE,
  lavender: LAVENDER_SCALE,
  peach: PEACH_SCALE,
  linen: LINEN_SCALE,
}

export const HEARD_ABOUT_OPTIONS: HeardAboutOption[] = [
  { id: 1, label: 'I found it on my own', value: 'I found it on my own (id: 1)', followUp: 'Where?' },
  {
    id: 2,
    label: 'I saw it on an Influencers Page',
    value: 'I saw it on an Influencers Page (id: 2)',
    followUp: 'What is their handle?',
  },
  {
    id: 3,
    label: 'My sales rep or business coach told me',
    value: 'My sales rep or business coach told me (id: 3)',
    followUp: 'What is their name?',
  },
  {
    id: 4,
    label: 'A friend that I know told me',
    value: 'A friend that I know told me (id: 4)',
    followUp: 'What is their name?',
  },
  { id: 5, label: 'I saw an ad', value: 'I saw an ad (id: 5)', followUp: 'Do you remember where?' },
  { id: 6, label: 'Other', value: 'Other (id:  6)', followUp: 'Tell us more!' },
]

export const BOOKING_SOFTWARE_OPTIONS: BookingSoftwareOption[] = [
  { id: 1, label: 'Aura' },
  { id: 2, label: 'EnvisionGo' },
  { id: 3, label: 'Fresha' },
  { id: 4, label: 'Gloss Genius' },
  { id: 5, label: 'Mangomint' },
  { id: 6, label: 'Meevo' },
  { id: 7, label: 'Phorest' },
  { id: 8, label: 'Rosy' },
  { id: 9, label: 'Schedulicity' },
  { id: 10, label: 'Square' },
  { id: 11, label: 'Vagaro' },
  { id: 12, label: 'None' },
  { id: 13, label: 'Other' },
]

export const SIGNUP_LOCALSTORAGE_KEYS = {
  email: 'signup-email',
}

export const MOCK_SCALE_QUANTITIES: ScaleQuantity[] = [
  // usa scales
  {id: BLACK_SCALE.id, quantity: 0, countryCode: 'US'},
  {id: LINEN_SCALE.id, quantity: 1, countryCode: 'US'},
  {id: BLACK_MINI_SCALE.id, quantity: 0, countryCode: 'US'},

  // canada scales
  {id: BLACK_SCALE.id, quantity: 1, countryCode: 'CA'},
  {id: LINEN_SCALE.id, quantity: 1, countryCode: 'CA'},
  {id: BLACK_MINI_SCALE.id, quantity: 0, countryCode: 'CA'},
]

