import { Box, Flex, Text, Tag, Button, Spinner } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { generatePath, Link, useLocation } from "react-router-dom"
import { getLocalStorageItem, setLocalStorageItem } from "../../core/localStorage"
import { UseBaseApiParams } from "../../core/UseBaseApiParams"
import { UseViewSize } from "../../core/UseViewSize"
import { dispatchDisconnectSquare } from "../../data/settings/api"
import { LoggedInUserSalonContext } from "../../data/user/interfaces"
import { useAppSelector } from "../../hooks"
import { Gap } from "../../mini-lib/gap/Gap"
import { MaterialIcon } from "../../mini-lib/icons/MaterialIcon"
import { PageLayout } from "../../mini-lib/layouts/PageLayout"
import { PageHeader } from "../../mini-lib/page-header/PageHeader"
import { COLORS } from "../../mini-lib/theme/colors"
import { selectLoadingState } from '../../core/loading/slice'
import { ROUTES } from "../../appRoutes";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { HelpStepperV2 } from "../start-guide-v2/HelpStepperV2";
import React from "react";
import { selectStepsCompleted } from "../../data/start-guide/slice";
import { ConfirmModal } from '../../mini-lib/confirm-modal/ConfirmModal'
import { HELP_DRAWER_STATES, HelpStepCompleteDrawer } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { reduceSetStepCompleteDrawerState } from '../../data/start-guide/slice'

export const IntegrationSettingsPage = () => {
  const { salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const breadcrumbs = [
    { label: 'Salon Settings', url: generatePath(ROUTES.salonSettings, { salonId }) },
    { label: 'Integrations' },
  ]

  const stepCodes = [CHECKLIST_CODES.learnPOS]
  const stepsCompleted = useAppSelector(( state ) => selectStepsCompleted(state, stepCodes))
  const previousStepLink = generatePath(ROUTES.sgPOSIntro, { salonId })
  const nextStepLink = generatePath(ROUTES.sgIndex, { salonId })
  return (
    <>
    <PageLayout
      variant="full"
      header={<PageHeader title="Integrations" breadcrumbs={breadcrumbs} />}
      content={
        <>
          {!!stepsCompleted && (
            <HelpStepperV2
                title="Connect Your POS System"
                titleIconName="clients"
                currentStepNumber={1}
                stepsComplete={stepsCompleted}
                totalSteps={stepCodes.length}
                previousStepLink={previousStepLink}
                nextStepLink={nextStepLink}
                guideType={CHECKLIST_CODES.learnPOS}
              />
          )}
          <IntegrationSettings />
        </>
      }
    />
     <HelpStepCompleteDrawer
        title="Way To Go! You are now connected to your POS system!"
        subtitle="Yor are finished your set up!"
        actions={
          <Flex gridGap="12px" borderRadius="15px" bg="white" p="0 12px">
            <Link to={generatePath(ROUTES.sgIndex, { salonId })}>
              <Button
                variant="round"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.hidden))
                }}
              >
                Return Home
              </Button>
            </Link>
          </Flex>
        }
        guideType={CHECKLIST_CODES.learnPOS}
      />
    </>
  )
}

export const IntegrationSettings = () => {
  return <>
    <Gap />
    <Flex gridGap='24px' wrap='wrap'>
      <SquareIntegrationTile />
    </Flex>
  </>
}

export const SquareIntegrationTile = () => {

  const { isMobile } = UseViewSize()
  const { search } = useLocation()
  const {user, salonId } = UseBaseApiParams()
  const status: IntegrationStatus = !!user?.currentSquareContext?.merchantId ? 'connected' : 'disconnected'
  const title = 'Square'
  const description = 'Clients, stylists, and appointments from Square integrate into SalonScale. SalonScale sends the session prices back to Square when complete and are added to the clients bill.'
  const logoUrl = require('../../assets/square-logo.png').default
  const redirectTo = search === ''
    ? generatePath(ROUTES.squareConnectTeam, { salonId })
    : generatePath(ROUTES.squareConnectTeam, { salonId }) + search;

  return (
    <Box w={isMobile ? '100%' : '350px'} borderRadius='8px' border='1px solid #E0E0E0;'>
      <Flex direction='column' margin='24px'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Flex alignItems='center'>
            <img src={logoUrl} alt="square logo" style={{ height: '32px', width: '32px' }} />
            <Gap size='8px' />
            <Text fontWeight='bold'>{title}</Text>
          </Flex>
          {
            status === 'connected' ?
              <Tag colorScheme='green' size='lg'>Connected</Tag> :
              <Tag colorScheme='gray' size='lg'>Disconnected</Tag>
          }
        </Flex>
        <Gap size='16px' />
        <Text color={COLORS.text_secondary} fontSize='14px'>{description}</Text>
        <Gap size='16px' />
        <Flex alignItems='center'>
          {user && status === 'connected' && <SquareDisconnectButton />}
          {user && status === 'disconnected' && (
            <SquareConnectButton />
              // <Link to={generatePath(ROUTES.squareConnectAuthorization, { salonId })}>
              //   <Button variant='round-outline-lower' leftIcon={<MaterialIcon name='add'/>}>Connect</Button>
              // </Link>
          )}
          {!user && <Spinner/>}
          <Gap size='16px' />
          {/* todo-square: update this link*/}
          {status === 'connected'
            ? (
              <Link to={redirectTo}>
                <Button variant="round" colorScheme="brand.midnight">
                  Manage
                </Button>
              </Link>
            ) : (
            <a href='https://help.salonscale.com/knowledge' target='_blank' rel="noreferrer">
              <Text color='brand.midnight.900'>Learn More</Text>
            </a>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

type IntegrationStatus = 'connected' | 'disconnected'

export const SquareConnectButton = () => {
  setLocalStorageItem('square-auth-state', randomHex())
  const authState = getLocalStorageItem('square-auth-state')
  return (
    <a href={buildSquareUrl({ authState: authState })} target='_blank' rel="noreferrer">
      <Button variant="round" colorScheme="brand.midnight">
        Connect
      </Button>
    </a>
  );
}

export const SquareDisconnectButton = () => {
  const { user, salonId } = UseBaseApiParams()
  const [showDisconnectModal, setShowDisconnectModal] = React.useState(false)

  const dispatch = useDispatch()

  const disconnectSquare = (token: string, salonId: number, currentSalonContext: LoggedInUserSalonContext) => {
    dispatch(
      dispatchDisconnectSquare({
        token,
        userId: user.userId,
        salonId: salonId,
        currentSalonContext: currentSalonContext,
        loadingKey: 'disconnect-square'
      })
    )
  }

  const isBusy = useAppSelector((state) => selectLoadingState(state, 'disconnect-square'))

  return (<>
    <ConfirmModal
      showModal={showDisconnectModal}
      title="Disconnect Square"
      subtitle="Are you sure you want to disconnect? Your sessions, stylists, and clients will not be deleted but their links to Square will be. This cannot be undone."
      confirmText="Yes, disconnect"
      onConfirm={() => {
        disconnectSquare(user.token, salonId, user.currentSalonContext!)
        setShowDisconnectModal(false)
      }}
      onCancel={() => { setShowDisconnectModal(false) }}
    />
    {
      isBusy ? (
        <Spinner h="16px" w="16px" />
      ) : (
        <Button
          variant="round-outline"
          leftIcon={<MaterialIcon name='remove' />}
          colorScheme="brand.midnight"
          onClick={() => { setShowDisconnectModal(true) }}
        >
          Disconnect
        </Button>
      )
    }
  </>)
}

const buildSquareUrl = ({ authState }: { authState: string }): string => {
  const baseUrl = process.env.REACT_APP_SQUARE_BASE_URL || ''
  const clientId = process.env.REACT_APP_SQUARE_CLIENT_ID || ''
  const auth_url = process.env.REACT_APP_SQUARE_AUTHORIZE_URL || ''
  const scope = [
    'APPOINTMENTS_READ',
    'APPOINTMENTS_WRITE',
    'APPOINTMENTS_ALL_READ',
    'APPOINTMENTS_ALL_WRITE',
    'APPOINTMENTS_BUSINESS_SETTINGS_READ',
    'CUSTOMERS_READ',
    'EMPLOYEES_READ',
    'ITEMS_READ',
    'MERCHANT_PROFILE_READ'
  ].join('+')

  return `${baseUrl}/${auth_url}client_id=${clientId}&scope=${scope}&state=${authState}&session=false`
}

const randomHex = () => {
  const array = new Uint8Array(32);
  crypto.getRandomValues(array);
  return Array.from(array).map(byte => byte.toString(16).padStart(2, '0')).join('');
};
