import React, { useEffect, useState } from 'react'

import { PageHeader } from '../../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../../core/UseBaseApiParams'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../appRoutes'
import { LaborOnboardCustomizeMenuContent } from './LaborOnboardContent'
import { Button, Flex } from '@chakra-ui/react'
import { CHECKLIST_CODES } from '../../../../data/start-guide/constants'
import { buildBasicLaborServices } from "../../../../data/labor/utils";
import { dispatchCreateLaborServices, dispatchListLaborServices } from "../../../../data/labor/api";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks";
import { selectLaborServiceList } from "../../../../data/labor/slice";

export const LaborSoloOnboardingPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user, salonId } = UseBaseApiParams()
  const laborServices = useAppSelector(selectLaborServiceList)
  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Services', url: generatePath(ROUTES.labor, { salonId }) },
    { label: 'Onboarding' },
  ]
  const [laborServiceType, setLaborServiceType] = useState('hourly')

  useEffect(() => {
    dispatch(dispatchListLaborServices({token: user.token, salonId}))
  }, [dispatch, user.token, salonId])

  const continueClicked = () => {
    if (laborServices && laborServices.length === 0) {
      const basicServices = buildBasicLaborServices(laborServiceType)
      dispatch(dispatchCreateLaborServices({salonId, userId: user.userId, token: user.token, models: basicServices}))
    }
    history.push(generatePath(ROUTES.labor, { salonId }) + `?guide=${CHECKLIST_CODES.addLaborSolo}`)
  }
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Services" breadcrumbs={breadcrumbs} showHelp={false} />}
      content={
        <LaborOnboardCustomizeMenuContent
          setLaborServiceType={setLaborServiceType}
          laborServiceType={laborServiceType}
          actions={
            <Flex justify="end" gridGap="12px" maxW="100%" w="500px">
              <Link to={generatePath(ROUTES.sgLaborIntro, { salonId })}>
                <Button variant="round-outline" colorScheme="brand.midnight">
                  Back
                </Button>
              </Link>
              <Button variant="round" colorScheme="brand.midnight" onClick={continueClicked}>
                Continue
              </Button>
            </Flex>
          }
        />
      }
    />
  )
}
