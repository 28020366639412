/////////////// api.ts
//
//

import axios, {AxiosResponse} from 'axios'

import {
  APILaborItem,
  APILaborService,
  APILaborServiceCreate,
  APILaborServiceUpdate,
  APILaborTier,
  APILaborTierCreate,
  APILaborTierServiceCreate,
  APILaborTierServiceUpdate,
  APILaborTierUpdate,
  LaborItem,
  LaborService,
  LaborTier,
  LaborTierService,
} from './interfaces'
import {
  mapAPILaborItemsToLaborTierServices,
  mapAPILaborServicesToLaborServices,
  mapAPILaborTiersToLaborTiers,
} from './mappers'

import { GetServerBaseUrl } from '../../env'
import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";

// labor item apis
//
//
export const apiListLaborItemsV2 = (params: {
  token: string,
  salonId: number,
  pageNumber?: number,
}): Promise<LaborTierService[]> => {

  const {token, salonId} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-items`
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: APILaborItem[] }>) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborItemsV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierServiceUpdate[]
}): Promise<LaborTierService[]> => {
  const { token, salonId, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-items/bulk-upsert`
  const body = {
    labor_items: models,
  }
  return axios
    .patch(url, body, config)
    .then((response: any) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateLaborItemsV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierServiceCreate[]
}): Promise<LaborTierService[]> => {
  const { token, salonId, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-items/bulk-upsert `
  const body = {
    labor_items: models,
  }
  return axios
    .post(url, body, config)
    .then((response: any) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborItemsV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborItem[]
}): Promise<any> => {
  const { token, salonId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-items/bulk-delete?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}



// labor tier apis
//
//
export const apiListLaborTiersV2 = (params: {token: string, salonId: number, pageNumber?: number}): Promise<LaborTier[]> => {
  const {token, salonId} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-tiers`
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: APILaborTier[] }>) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}


export const apiUpdateLaborTiersV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierUpdate[]
}): Promise<LaborTier[]> => {
  const { token, salonId, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-tiers/bulk-upsert`
  const body = {
    labor_tiers: models,
  }
  return axios
    .patch(url, body, config)
    .then((response: any) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateLaborTiersV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierCreate[]
}): Promise<LaborTier[]> => {
  const { token, salonId, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-tiers/bulk-upsert`
  const body = {
    token,
    labor_tiers: models,
  }
  return axios
    .post(url, body, config)
    .then((response: any) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteLaborTiersV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborTier[]
}): Promise<any> => {
  const { token, salonId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-tiers/bulk-delete?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}

// labor service apis
//
//
export const apiListLaborServicesV2 = (params: {token: string, salonId: number, pageNumber?: number}): Promise<LaborService[]> => {
  const {token, salonId} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-services`
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: APILaborService[] }>) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}


export const apiUpsertLaborServicesV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborServiceCreate[] | APILaborServiceUpdate[]
}): Promise<LaborService[]> => {
  const { token, salonId, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-services/bulk-upsert`
  const body = {
    salonId: salonId,
    labor_services: models,
  }
  return axios
    .post(url, body, config)
    .then((response: any) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborServicesV2 = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborService[]
}): Promise<any> => {
  const { token, salonId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labor-services/bulk-delete?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}