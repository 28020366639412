import { LABOR_SERVICE_TYPES, LaborItem, LaborService, LaborTier } from '../../data/labor/interfaces'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { buildTierServiceKey, buildTierServiceMap } from '../../data/labor/utils'
import { BRAND_THEMES } from '../../theme'
import {
  LaborServiceAddCell,
  LaborServiceCell,
  LaborTierCell,
  LaborTierServiceCell,
} from './LaborTableCells'
import { UseViewSize } from '../../core/UseViewSize'
import { stringNumberSort } from '../../mini-lib/utils/sorting'
import { ALL_LABOR_CATEGORIES, DEFAULT_SERVICE_NAME, DEFAULT_TIER_NAME } from '../../data/labor/constants'
import { TableCell } from '../../mini-lib/table/Table'
import { keys } from 'lodash'
import { COLORS } from '../../mini-lib/theme/colors'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { useAppSelector } from "../../hooks";
import {
  selectLaborServicesByCategory,
  selectSelectedLaborCategory
} from "../../data/labor/slice";

export const LaborTable = (props: {
  laborItems: LaborItem[]
  laborServices: LaborService[]
  laborTiers: LaborTier[]
}) => {
  const { laborItems, laborTiers } = props

  const laborServicesByCategory = useAppSelector(selectLaborServicesByCategory)

  // this builds a map by tier-service id (they should be unique)
  // this is much more performant than trying to find an item by tier.id and service.id later
  // todo: consider extracting this to a selector or memoize it
  const laborItemsByTierService: { [key: string]: LaborItem } = buildTierServiceMap(laborItems)
  const selectedLaborCategory = useAppSelector(selectSelectedLaborCategory)

  return (
    <Box>
      {keys(laborServicesByCategory).map((category, categoryIndex) => {
        const servicesForCategory = laborServicesByCategory ? laborServicesByCategory[category] : []
        const showCategory = selectedLaborCategory === category || selectedLaborCategory === ALL_LABOR_CATEGORIES
        return (
          <Box key={category}>
            {showCategory && (
              <CategoryBox
                key={category}
                categoryName={category}
                laborTiers={laborTiers}
                laborServices={servicesForCategory}
                laborItemsByTierService={laborItemsByTierService}
                categoryIndex={categoryIndex}
              />
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export const CategoryBox = (props: {
  categoryName: string
  laborServices: LaborService[]
  laborTiers: LaborTier[]
  laborItemsByTierService: { [key: string]: LaborItem }
  categoryIndex: number
}) => {
  const { categoryName, laborItemsByTierService, laborServices, laborTiers, categoryIndex } = props
  const { isMobile } = UseViewSize()
  const [showCategoryContent, setShowCategoryContent] = useState(true)
  return (
    <Box key={categoryName}>
      <CategoryHeader
        showCategoryContent={showCategoryContent}
        setShowCategoryContent={setShowCategoryContent}
        categoryName={categoryName}
        numServices={laborServices.length}
      />
      <Flex overflow="scroll">
        {showCategoryContent && (
          <>
            {/* Labor Services Column */}
            <LaborServicesCol categoryName={categoryName} laborServices={laborServices} laborTiers={laborTiers} categoryIndex={categoryIndex}/>

            {/* Tiers */}
            <Flex ml={isMobile ? '12px' : ''}>
              {laborTiers && stringNumberSort(laborTiers, 'name', DEFAULT_TIER_NAME).map((tier, index) => {
                // individual tier column component
                return (
                  <LaborTierCol
                    key={tier.id}
                    tier={tier}
                    laborServices={laborServices}
                    laborItemsByTierService={laborItemsByTierService}
                    tierIndex={index}
                    showTierHeader={laborTiers.length > 1}
                    categoryIndex={categoryIndex}
                  />
                )
              })}
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  )
}
export const CategoryHeader = (props: {
  categoryName: string
  numServices: number
  showCategoryContent: boolean
  setShowCategoryContent: (show: boolean) => void
}) => {
  const { categoryName, numServices, showCategoryContent, setShowCategoryContent } = props
  return (
    <Box
      borderRadius="8px"
      p="12px"
      w="100%"
      bg={COLORS.skylight_50}
      cursor="pointer"
      onClick={() => setShowCategoryContent(!showCategoryContent)}
    >
      <Flex align="center" gridGap="12px">
        <MaterialIcon name={showCategoryContent ? 'expand_less' : 'expand_more'} />
        <Box>
          <Text variant="default/regular/title3">{categoryName}</Text>
          <Text color={COLORS.text_secondary}>{numServices} services</Text>
        </Box>
      </Flex>
    </Box>
  )
}
export const LaborServicesCol = (props: {
  categoryName: string;
  laborServices: LaborService[],
  laborTiers: LaborTier[]
  categoryIndex: number
}) => {
  const { laborServices, categoryName, laborTiers, categoryIndex } = props
  const { isMobile } = UseViewSize()
  const width = isMobile
    ? '200px'
    : laborTiers.length > 1
      ? '250px'
      : '500px'
  return (
    <Box m={isMobile ? '' : '12px'}>
      <TableCell width={width} />
      {laborServices && (
        <>
          {stringNumberSort(laborServices, 'name', DEFAULT_SERVICE_NAME).map((service, index) => {
            return (
              <TableCell key={service.id} width={width}>
                <LaborServiceCell service={service} theme={BRAND_THEMES.skylight} serviceIndex={index} categoryIndex={categoryIndex}/>
              </TableCell>
            )
          })}
          <TableCell width={width}>
            <LaborServiceAddCell categoryName={categoryName} serviceType={LABOR_SERVICE_TYPES.hourly} theme={BRAND_THEMES.skylight} index={categoryIndex} />
          </TableCell>
        </>
      )}
      {!laborServices && <TableCell width={width} />}

      <TableCell width={width} />
    </Box>
  )
}

export const LaborTierCol = (props: {
  tier: LaborTier
  laborServices: LaborService[]
  laborItemsByTierService: { [key: string]: LaborItem }
  showTierHeader: boolean
  tierIndex: number
  categoryIndex: number
}) => {
  const { tier, laborServices, laborItemsByTierService, tierIndex, categoryIndex, showTierHeader } = props
  const { isMobile } = UseViewSize()
  return (
    <Box key={tier.id} m={isMobile ? '0 12px 0 0' : '12px'}>
      <TableCell>
        {showTierHeader && <LaborTierCell tier={tier} tierIndex={tierIndex} />}
      </TableCell>

      {/*pricing for hourly items by tier*/}
      {laborServices &&
        laborServices.map((service, index) => {
          return (
            <TableCell key={buildTierServiceKey(tier.id, service.id)}>
              <LaborTierServiceCell
                service={service}
                tier={tier}
                laborItemsByTierService={laborItemsByTierService}
                type={service.type}
                serviceIndex={index}
                tierIndex={tierIndex}
                categoryIndex={categoryIndex}
              />
            </TableCell>
          )
        })}
    </Box>
  )
}
