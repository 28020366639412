// todos
// 1: add session to RootState located at src/hooks.ts
// 2: ensure model has 'id', change it to unique property if necessary

// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { BLACK_SCALE, LAVENDER_SCALE, LINEN_SCALE, MIDNIGHT_SCALE, PEACH_SCALE } from './constants'
import { Scale, ScaleQuantity } from './interfaces'
import { CBSalonType, CBSubscriptionType } from '../../integrations/chargebee/constants'
import { keyBy } from 'lodash'
import { getCountryCode } from "../../mini-lib/time/utils";

// state
//
interface SignupState {
  salonType: CBSalonType
  subscriptionType: CBSubscriptionType
  selectedFreeScale: Scale
  scaleQuantities: { [key: string]: number }
  scaleQuantitiesInStock: ScaleQuantity[] | null
  heardAboutUsOptionValue: string | null
  heardAboutUsExtraDetails: string | null
  isSignupCartOpen: boolean
  availableScales: Scale[]
  allowSmsContact: boolean
}

const initialState: SignupState = {
  salonType: 'team',
  subscriptionType: 'yearly',
  selectedFreeScale: BLACK_SCALE,
  scaleQuantities: {
    [BLACK_SCALE.id]: 0,
    [MIDNIGHT_SCALE.id]: 0,
    [PEACH_SCALE.id]: 0,
    [LAVENDER_SCALE.id]: 0,
    [LINEN_SCALE.id]: 0,
  },
  scaleQuantitiesInStock: null,
  heardAboutUsOptionValue: null,
  heardAboutUsExtraDetails: null,
  isSignupCartOpen: false,
  availableScales: [BLACK_SCALE],
  allowSmsContact: true
}

// reducer
//
export const SignupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    reduceSetSalonType: (state, action: PayloadAction<CBSalonType>) => {
      state.salonType = action.payload
    },
    reduceSetSubscriptionType: (state, action: PayloadAction<CBSubscriptionType>) => {
      state.subscriptionType = action.payload
    },
    reduceSetSelectedFreeScale: (state, action: PayloadAction<Scale>) => {
      state.selectedFreeScale = action.payload
    },
    reduceSetScaleQuantity: (state, action: PayloadAction<{ scale: Scale; quantity: number }>) => {
      state.scaleQuantities[action.payload.scale.id] = action.payload.quantity
    },
    reduceSetScaleQuantities: (state, action: PayloadAction<{ [key: string]: number }>) => {
      state.scaleQuantities = action.payload
    },
    reduceSetScaleQuantitiesInStock: (state, action: PayloadAction<ScaleQuantity[]>) => {
      state.scaleQuantitiesInStock = action.payload
    },
    reduceSetHeardAboutUsOptionValue: (state, action: PayloadAction<string>) => {
      state.heardAboutUsOptionValue = action.payload
    },
    reduceSetHeardAboutUsExtraDetails: (state, action: PayloadAction<string>) => {
      state.heardAboutUsExtraDetails = action.payload
    },
    reduceSetIsSignupCartOpen: (state, action: PayloadAction<boolean>) => {
      state.isSignupCartOpen = action.payload
    },
    reduceAvailableScales: (state, action: PayloadAction<Scale[]>) => {
      state.availableScales = action.payload
    },
    reduceAllowSmsContact: (state, action: PayloadAction<boolean>) => {
      state.allowSmsContact = action.payload
    },
  },
})

// actions
//
export const {
  reduceSetSalonType,
  reduceSetSubscriptionType,
  reduceSetSelectedFreeScale,
  reduceSetScaleQuantity,
  reduceSetScaleQuantities,
  reduceSetHeardAboutUsOptionValue,
  reduceSetHeardAboutUsExtraDetails,
  reduceSetIsSignupCartOpen,
  reduceAvailableScales,
  reduceAllowSmsContact,
  reduceSetScaleQuantitiesInStock,
} = SignupSlice.actions


// selectors
//
export const selectSalonType = (state: RootState) => state.signup.salonType
export const selectSubscriptionType = (state: RootState) => state.signup.subscriptionType
export const selectSelectedFreeScale = (state: RootState) => state.signup.selectedFreeScale
export const selectScaleQuantities = (state: RootState) => state.signup.scaleQuantities
export const selectHeardAboutUsOptionValue = (state: RootState) => state.signup.heardAboutUsOptionValue
export const selectHeardAboutUsExtraDetails = (state: RootState) => state.signup.heardAboutUsExtraDetails
export const selectIsSignupCartOpen = (state: RootState) => state.signup.isSignupCartOpen
export const selectAvailableScales = (state: RootState) => state.signup.availableScales
export const selectAllowSmsContact = (state: RootState) => state.signup.allowSmsContact
export const selectScaleQuantitiesInStockForCountryById = (state: RootState) => {
  const allScalesInStock = state.signup.scaleQuantitiesInStock

  if (!allScalesInStock) {return null}

  const countryCode = getCountryCode()

  if (countryCode === "US" || countryCode === 'CA') {
    const filteredScalesInStock: ScaleQuantity[] = allScalesInStock
      .filter(scaleQuantity => scaleQuantity.countryCode.toLowerCase() === countryCode.toLowerCase())
    return keyBy(filteredScalesInStock, 'id')
  }

  return {}
}

// export
//
export default SignupSlice.reducer
