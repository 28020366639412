import React, { useEffect, useState } from 'react'

import { PageHeader } from '../../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../../core/UseBaseApiParams'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../appRoutes'
import {
  LaborOnboardCustomizeMenuContent,
  LaborOnboardHasTiersContent,
  LaborOnboardTierAssignContent,
  LaborOnboardTierCreateContent,
} from './LaborOnboardContent'
import { Button, Flex } from '@chakra-ui/react'
import { CHECKLIST_CODES } from '../../../../data/start-guide/constants'
import { buildBasicLaborServices } from "../../../../data/labor/utils";
import { dispatchCreateLaborServices, dispatchListLaborServices } from "../../../../data/labor/api";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks";
import { selectLaborServiceList } from "../../../../data/labor/slice";

export const LaborTeamOnboardingPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user, salonId } = UseBaseApiParams()

  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Services', url: generatePath(ROUTES.labor, { salonId }) },
    { label: 'Onboarding' },
  ]
  const [contentId, setContentId] = useState(CONTENT_IDS.hasTier)
  const [laborServiceType, setLaborServiceType] = useState('hourly')
  const [hasTiers, setHasTiers] = useState(false)

  const laborServices = useAppSelector(selectLaborServiceList)

  useEffect(() => {
    dispatch(dispatchListLaborServices({token: user.token, salonId}))
  }, [dispatch, user.token, salonId])

  const continueClicked = () => {
    if (laborServices && laborServices.length === 0) {
      const basicServices = buildBasicLaborServices(laborServiceType)
      dispatch(dispatchCreateLaborServices({salonId, userId: user.userId, token: user.token, models: basicServices}))
    }
    history.push(generatePath(ROUTES.labor, { salonId }) + `?guide=${CHECKLIST_CODES.addLaborTeam}`)
  }
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Services" breadcrumbs={breadcrumbs} showHelp={false} />}
      content={
        <>
          {contentId === CONTENT_IDS.hasTier && (
            <LaborOnboardHasTiersContent hasTiers={hasTiers} setHasTiers={setHasTiers} setContentId={setContentId} />
          )}
          {contentId === CONTENT_IDS.tierCreate && <LaborOnboardTierCreateContent setContentId={setContentId} />}
          {contentId === CONTENT_IDS.tierAssign && <LaborOnboardTierAssignContent setContentId={setContentId} />}
          {contentId === CONTENT_IDS.menu && (
            <LaborOnboardCustomizeMenuContent
              setLaborServiceType={setLaborServiceType}
              laborServiceType={laborServiceType}
              actions={
                <Flex justify="end" gridGap="12px" maxW="100%" w="500px">
                  <Button
                    onClick={() => setContentId(CONTENT_IDS.tierAssign)}
                    variant="round-outline"
                    colorScheme="brand.midnight"
                  >
                    Back
                  </Button>
                  <Button variant="round" colorScheme="brand.midnight" onClick={continueClicked}>
                    Continue
                  </Button>
                </Flex>
              }
            />
          )}
        </>
      }
    />
  )
}

export const CONTENT_IDS = {
  menu: 'menu',
  hasTier: 'has-tier',
  tierCreate: 'tier-create',
  tierAssign: 'tier-assign',
}
