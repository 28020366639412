/////////////// api.ts
//
//

import {Dispatch} from '@reduxjs/toolkit'

import {
  reduceCreateLaborItems,
  reduceCreateLaborServices,
  reduceCreateLaborTiers,
  reduceDeleteLaborTierServices,
  reduceDeleteLaborServices,
  reduceDeleteLaborTiers,
  reduceListLaborItems,
  reduceListLaborServices,
  reduceListLaborTiers,
  reduceUpdateLaborItems,
  reduceUpdateLaborServices,
  reduceUpdateLaborTiers, selectLaborServicesByCategory,
} from './slice'
import {
  APILaborServiceCreate,
  APILaborServiceUpdate,
  APILaborTierCreate,
  APILaborTierServiceCreate,
  APILaborTierServiceUpdate,
  APILaborTierUpdate,
  LaborItem,
  LaborService,
  LaborTier,
  LaborTierService,
} from './interfaces'

import { delay } from 'lodash'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { dollarsToCents } from '../../core/money/utils'
import { RootState } from "../../store";
import {
  apiCreateLaborItemsV2, apiCreateLaborTiersV2, apiUpsertLaborServicesV2,
  apiDeleteLaborItemsV2, apiDeleteLaborServicesV2, apiDeleteLaborTiersV2,
  apiListLaborItemsV2, apiListLaborServicesV2,
  apiListLaborTiersV2,
  apiUpdateLaborItemsV2, apiUpdateLaborTiersV2
} from "./apiv2";
import {
  apiCreateLaborItems, apiCreateLaborServices, apiCreateLaborTiers, apiDeleteLaborServices, apiDeleteLaborTiers,
  apiDeleteLaborTierServices,
  apiListLaborItems, apiListLaborServices,
  apiListLaborTiers,
  apiUpdateLaborItems, apiUpdateLaborTiers
} from "./apiV1";
import { LABOR_LOADING } from "./constants";


// labor item actions
//
//
export const dispatchListLaborItems = (params: {
  token: string,
  salonId: number,
  pageNumber?: number,
  searchText?: string
  version?: 'v1' | 'v2'
}) => {
  const {version = 'v2'} = params
  const apiList = version === 'v1' ? apiListLaborItems : apiListLaborItemsV2
  return (dispatch: Dispatch) => {
    return apiList(params).then((resp) => {
      dispatch(reduceListLaborItems(resp))
    })
  }
}
export const dispatchUpdateLaborItems = (params: {
  token: string
  userId: number
  salonId: number
  loadingId?: string,
  models: APILaborTierServiceUpdate[]
  version?: 'v1' | 'v2'
}) => {
  const {version = 'v2', loadingId = LABOR_LOADING.updateItems} = params
  return (dispatch: Dispatch) => {
    if (version === 'v1') {
      return apiUpdateLaborItems(params)
        .then((resp) => {
          dispatch(reduceUpdateLaborItems(resp))
          delay(() => {
            dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
          }, 2000)
        })
        .catch((error) => {
          throw error
        })
    } else {
      return apiUpdateLaborItemsV2(params)
        .then((resp) => {
          dispatch(reduceUpdateLaborItems(resp))
          delay(() => {
            dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
          }, 2000)
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
export const dispatchCreateLaborItems = (params: {
  token: string
  userId: number
  salonId: number
  loadingId?: string,
  models: APILaborTierServiceCreate[],
  version?: 'v1' | 'v2'
}) => {
  const {version = 'v2', loadingId = LABOR_LOADING.createItems} = params
  return (dispatch: Dispatch) => {
    if (version === 'v1') {
      return apiCreateLaborItems(params)
        .then((resp) => {
          dispatch(reduceCreateLaborItems(resp))
          delay(() => {
            dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
          }, 2000)
        })
        .catch((error) => {
          throw error
        })
    } else {
      return apiCreateLaborItemsV2(params)
        .then((resp) => {
          dispatch(reduceCreateLaborItems(resp))
          delay(() => {
            dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
          }, 2000)
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
export const dispatchDeleteLaborTierServices = (params: {
  token: string
  userId: number
  salonId: number
  loadingId?: string,
  models: LaborItem[],
  version?: 'v1' | 'v2'
}) => {
  const {version = 'v2', loadingId = LABOR_LOADING.deleteItems} = params
  return (dispatch: Dispatch) => {
    if (version === 'v1') {
      return apiDeleteLaborTierServices(params)
        .then((resp) => {
          dispatch(reduceDeleteLaborTierServices(resp))
          delay(() => {
            dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
          }, 2000)
        })
        .catch((error) => {
          throw error
        })
    } else {
      return apiDeleteLaborItemsV2(params)
        .then((resp) => {
          dispatch(reduceDeleteLaborTierServices(resp))
          delay(() => {
            dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
          }, 2000)
        })
        .catch((error) => {
          throw error
        })
    }

  }
}

// labor tier actions
//
//
export const dispatchListLaborTiers = (params: {
    token: string,
    salonId: number,
    pageNumber?: number,
    searchText?: string
    version?: 'v1' | 'v2'
    loadingId?: string
}) => {
  const { loadingId = LABOR_LOADING.duplicateServices} = params
  return (dispatch: Dispatch) => {
      dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    if (params.version === 'v1') {
      return apiListLaborTiers(params).then((resp) => {
        dispatch(reduceListLaborTiers(resp))
        dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
      })
    } else {
      return apiListLaborTiersV2(params).then((resp) => {
        dispatch(reduceListLaborTiers(resp))
        dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
      })
    }
  }
}
export const dispatchUpdateLaborTiers = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierUpdate[]
  version?: 'v1' | 'v2'
  loadingId?: string
}) => {
  const {loadingId = LABOR_LOADING.updateTiers} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    if (params.version === 'v1') {
      return apiUpdateLaborTiers(params)
        .then((resp) => {
          dispatch(reduceUpdateLaborTiers(resp))
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        })
        .catch((error) => {
          throw error
        })
    } else {
      return apiUpdateLaborTiersV2(params)
        .then((resp) => {
          dispatch(reduceUpdateLaborTiers(resp))
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
export const dispatchCreateLaborTiers = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierCreate[]
  version?: 'v1' | 'v2'
  loadingId?: string
}) => {
  const {loadingId = LABOR_LOADING.updateTiers} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    if (params.version === 'v1') {
      return apiCreateLaborTiers(params)
        .then((resp) => {
          dispatch(reduceCreateLaborTiers(resp))
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        })
        .catch((error) => {
          throw error
        })
    } else {
      return apiCreateLaborTiersV2(params)
        .then((resp) => {
          dispatch(reduceCreateLaborTiers(resp))
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
export const dispatchDuplicateLaborTier = (params: {
  token: string
  userId: number
  salonId: number
  tier: LaborTier
  tierServicesForTier: LaborTierService[]
  loadingId?: string
}) => {
  const { token, userId, salonId, tier, tierServicesForTier, loadingId = LABOR_LOADING.duplicateTiers } = params
  const copyTier: APILaborTierCreate = { name: `${tier.name} copy` }
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    // todo: version this
    return apiCreateLaborTiers({
      token,
      userId,
      salonId,
      models: [copyTier],
    })
      .then((resp) => {
        const copyItems: APILaborTierServiceCreate[] = tierServicesForTier.map((tierService) => {
          return {
            tier_id: resp[0].id,
            service_id: tierService.serviceId,
            price: dollarsToCents(tierService.priceDollars),
          }
        })
        dispatch(reduceCreateLaborTiers(resp))
        dispatch(dispatchCreateLaborItems({ token, userId, salonId, loadingId: '', models: copyItems }) as any)
        dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteLaborTiers = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborTier[]
  version?: 'v1' | 'v2'
  loadingId?: string
}) => {
  const {loadingId = LABOR_LOADING.deleteTiers} = params
  return (dispatch: Dispatch) => {
  dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    if (params.version === 'v1') {
      return apiDeleteLaborTiers(params)
        .then((resp) => {
          dispatch(reduceDeleteLaborTiers(resp))
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        })
        .catch((error) => {
          throw error
        })
    } else {
      return apiDeleteLaborTiersV2(params)
        .then((resp) => {
          dispatch(reduceDeleteLaborTiers(resp))
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        })
        .catch((error) => {
          throw error
        })
    }
  }
}

// labor tier actions
//
//
export const dispatchListLaborServices = (params: {
  token: string,
  salonId: number,
  pageNumber?: number,
  loadingId?: string,
  version?: 'v1' | 'v2'
}) => {
  const {loadingId = LABOR_LOADING.listServices} = params
  const apiList = params.version === 'v1' ? apiListLaborServices : apiListLaborServicesV2
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    return apiList(params).then((resp) => {
      dispatch(reduceListLaborServices(resp))
      dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
    })
  }
}
export const dispatchUpdateLaborServices = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborServiceUpdate[]
  loadingId?: string,
  version?: 'v1' | 'v2'
}) => {
  const {loadingId = LABOR_LOADING.listServices} = params
  const apiUpsert = params.version === 'v1' ? apiCreateLaborServices : apiUpsertLaborServicesV2
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    return apiUpsert(params)
      .then((resp) => {
        dispatch(reduceUpdateLaborServices(resp))
        dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchCreateLaborServices = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborServiceCreate[]
  loadingId?: string,
  version?: 'v1' | 'v2'
}) => {
  const {loadingId = LABOR_LOADING.createServices} = params
  const apiUpsert = params.version === 'v1' ? apiCreateLaborServices : apiUpsertLaborServicesV2
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    return apiUpsert(params)
      .then((resp) => {
        dispatch(reduceCreateLaborServices(resp))
        dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteLaborServices = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborService[]
  loadingId?: string,
  version?: 'v1' | 'v2'
}) => {
  const {loadingId = LABOR_LOADING.deleteServices} = params
  const apiDelete = params.version === 'v1' ? apiDeleteLaborServices : apiDeleteLaborServicesV2
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    return apiDelete(params)
      .then((resp) => {
        dispatch(reduceDeleteLaborServices(resp))
        dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchCreateLaborServicesAndLaborTierServices = (params: {
  token: string
  userId: number
  salonId: number
  tiers: LaborTier[]
  services: APILaborServiceCreate[]
  tierPriceMap: { [key: string]: number }
  version?: 'v1' | 'v2'
}) => {
  return (dispatch: Dispatch) => {
    const { token, userId, salonId, tiers, services, tierPriceMap , version = 'v2'} = params
    const apiUpsert = version === 'v1' ? apiCreateLaborServices : apiUpsertLaborServicesV2
    return apiUpsert({ token, userId, salonId, models: services })
      .then((resp) => {
        const tierServicesToCreate: APILaborTierServiceCreate[] = []
        tiers.forEach((tier) => {
          const priceForTier = tierPriceMap[tier.id]
          resp.forEach((service) => {
            tierServicesToCreate.push({
              tier_id: tier.id,
              service_id: service.id,
              price: priceForTier ? dollarsToCents(priceForTier) : 0,
            })
          })
        })
        dispatch(reduceDeleteLaborServices(resp))
        dispatch(
          dispatchCreateLaborItems({
            token,
            userId: userId,
            salonId: salonId,
            loadingId: '',
            models: tierServicesToCreate,
          }) as any,
        )
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDuplicateLaborService = (params: {
  token: string
  userId: number
  salonId: number
  service: LaborService
  tierServicesForService: LaborTierService[]
  loadingId?: string,
  version?: 'v1' | 'v2'
}) => {
  const { token, userId, salonId, service, tierServicesForService, version = 'v2' , loadingId = LABOR_LOADING.duplicateServices} = params
  const copyService: APILaborServiceCreate = { name: `${service.name} copy`, type: service.type, category: service.category }
  const apiUpsert = version === 'v1' ? apiCreateLaborServices : apiUpsertLaborServicesV2
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true}))
    return apiUpsert({
      token,
      userId,
      salonId,
      models: [copyService],
    })
      .then((resp) => {
        const copyItems: APILaborTierServiceCreate[] = tierServicesForService.map((tierService) => {
          return {
            tier_id: tierService.tierId,
            service_id: resp[0].id,
            price: dollarsToCents(tierService.priceDollars),
          }
        })
        dispatch(reduceCreateLaborServices(resp))
        dispatch(dispatchCreateLaborItems({ token, userId: userId, salonId: salonId, loadingId: '', models: copyItems }) as any)
        dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}


// stubs
export const dispatchDeleteLaborCategory = (params: {
  token: string
  userId: number
  salonId: number
  category: string
  loadingId?: string,
}) => {
  const {
    token,
    userId,
    salonId,
    category,
    loadingId
  } = params

  return (dispatch: Dispatch | any, getState: () => RootState) => {
    const state = getState()
    const laborServicesByCategory = selectLaborServicesByCategory(state)
    const laborServicesForCategory = laborServicesByCategory ? laborServicesByCategory[ category] : []
    dispatch(dispatchDeleteLaborServices({token, userId: userId, salonId: salonId, models: laborServicesForCategory, loadingId}))
  }
}