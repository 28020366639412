import { configureStore } from '@reduxjs/toolkit'
import sidenavReducer from './mini-lib/sidenav/slice'
import settingsReducer from './pages/settings/settingsSlice'
import homeReducer from './pages/home/homeSlice'
import loadingReducer from './core/loading/slice'
import uploadReducer from './core/file-upload/slice'
import salonUserReducer from './data/salon-user/slice'
import userReducer from './data/user/slice'
import salonReducer from './data/salon/slice'
import integrationsReducer from './data/integrations/slice'
import multisalonReducer from './data/multi-salon/slice'
import signupReducer from './data/signup/slice'
import clientReducer from './data/clients/slice'
import sessionReducer from './data/sessions/slice'
import productReducer from './data/products/slice'
import masterProductReducer from './data/master-products/slice'
import extensionReducer from './data/extensions/slice'
import emptyBowlReducer from './data/empty-bowls/slice'
import laborReducer from './data/labor/slice'
import analyticsReducer from './data/analytics/slice'
import suppliesReducer from './data/supplies/slice'
import ordersReducer from './data/orders/slice'
import reportsReducer from './data/report/slice'
import reportsV2Reducer from './data/reports-v2/slice'
import permissionReducer from './data/settings/slice'
import startGuideReducer from './data/start-guide/slice'
import inventoryReducer from './data/inventory/slice'
import laborReportsReducer from './data/labor-report/slice'
import notification from './data/notification-user/slice'
import globalRolesReducer from './data/global-roles/slice'
import productUpcsReducer from './data/product-upcs/slice'
import adminAnalyticsReducer from './data/admin-data/slice'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'

const loggerMiddleware = (storeAPI: any) => (next: any) => (action: any) => {
  if (process.env.REACT_APP_IS_PRODUCTION !== 'true') {
    console.info('dispatching', action)
    let result = next(action)
    console.info('next state', storeAPI.getState())
    return result
  } else {
    let result = next(action)
    return result
  }
}
// ref: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const store = configureStore({
  reducer: {
    // core state
    loading: loadingReducer,
    fileupload: uploadReducer,
    signup: signupReducer,

    // date state
    users: userReducer,
    salonUsers: salonUserReducer,
    salons: salonReducer,
    clients: clientReducer,
    sessions: sessionReducer,
    products: productReducer,
    masterProducts: masterProductReducer,
    extensions: extensionReducer,
    emptybowls: emptyBowlReducer,
    integrations: integrationsReducer,
    labors: laborReducer,
    analytics: analyticsReducer,
    supplies: suppliesReducer,
    reports: reportsReducer,
    reportsV2: reportsV2Reducer,
    laborReports: laborReportsReducer,
    orders: ordersReducer,
    permissions: permissionReducer,
    startGuide: startGuideReducer,
    inventory: inventoryReducer,
    notification: notification,
    globalRoles: globalRolesReducer,
    productUpc: productUpcsReducer,

    // component state
    multisalon: multisalonReducer,
    sidenav: sidenavReducer,
    settings: settingsReducer,
    home: homeReducer,

    // admin state
    adminAnalytics: adminAnalyticsReducer
  },
  middleware: [loggerMiddleware, thunk],
  enhancers: [sentryReduxEnhancer],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
