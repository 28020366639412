/////////////// utils.ts
//
//

import { APILaborServiceCreate, LABOR_SERVICE_TYPES, LaborItem, LaborService, LaborTier } from './interfaces'
import { DEFAULT_TIER_NAME } from "./constants";

export const EMPTY_TIER = (): LaborTier => {
  return {
    id: -1,
    name: '',
  }
}
export const EMPTY_SERVICE = (): LaborService => {
  return {
    id: -1,
    name: '',
    type: '',
    category: '',
  }
}

export const EMPTY_LABOR = (): LaborItem => {
  return {
    id: -1,
    priceDollars: 0,
    tier: EMPTY_TIER(),
    service: EMPTY_SERVICE(),
  }
}

export const splitLaborServicesByType = (
  items: LaborService[] | null,
): { hourlyServices: LaborService[]; flatServices: LaborService[] } => {
  if (!items) {
    return { hourlyServices: [], flatServices: [] }
  }
  const flat: LaborService[] = []
  const hourly: LaborService[] = []
  items.forEach((item) => {
    if (item.type === LABOR_SERVICE_TYPES.service) {
      flat.push(item)
    } else {
      hourly.push(item)
    }
  })

  return { hourlyServices: hourly, flatServices: flat }
}

export const groupLaborServicesByCategory = (
  items: LaborService[] | null,
): { [category: string]: LaborService[] } | null => {

  if (!items) {
    return null
  }

  const map: { [category: string]: LaborService[] } = {}

  items.forEach((item) => {
    const category = item.category
    // initialize array if category doesn't exist
    if (!map[category]) {
      map[category] = []
    }
    map[category].push(item)
  })

  return map
}

export const buildTierServiceKey = (tierId: number, serviceId: number): string => `${tierId}-${serviceId}`

export const buildTierServiceMap = (items: LaborItem[]): { [key: string]: LaborItem } => {
  const itemsByTierService: { [key: string]: LaborItem } = {}
  items.forEach((item) => {
    itemsByTierService[buildTierServiceKey(item?.tier?.id, item?.service?.id)] = item
  })
  return itemsByTierService
}

export const buildLoadingIdentifier = (
  type: 'item' | 'service' | 'tier',
  tierId?: number,
  serviceId?: number,
): string => {
  if (type === 'item') {
    return `labor-item-${tierId}-${serviceId}`
  }
  if (type === 'service') {
    return `labor-service-${serviceId}`
  }
  if (type === 'tier') {
    return `labor-tier-${tierId}`
  }
  return ''
}

export const sortTiers = (tiers) => {
  return tiers.sort((a, b) => {
    // if the name is "New Tier", push it to the bottom
    if (a.name === DEFAULT_TIER_NAME) return 1
    if (b.name === DEFAULT_TIER_NAME) return -1

    // otherwise, sort alphabetically
    return a.name.localeCompare(b.name)
  })
}

export const buildBasicLaborServices = (serviceType: string): APILaborServiceCreate[] => {
  return [
    {name: 'Hair Cut & Blow Out', type: serviceType, category: 'Hair Cuts + Styling'},
    {name: 'Style', type: serviceType, category: 'Hair Cuts + Styling'},
    {name: 'Balayage', type: serviceType, category: 'Full Color Services'},
    {name: 'Full Highlights', type: serviceType, category: 'Full Color Services'},
    {name: 'Color Correction', type: serviceType, category: 'Full Color Services'},
    {name: 'Single Process Color', type: serviceType, category: 'Full Color Services'},
  ]
}