/////////////// interfaces.ts
//
//

// external interfaces
//
//

// labor items
//
export interface APILaborItem {
  id: number
  price_cents: number // note: django api field is called 'price'
  tier: {
    id: number
    name: string
  }
  service: {
    id: number
    name: string
    type: string // 'service' | 'hourly'
    category: string
  }
}

export interface APILaborTierServiceCreate {
  tier_id: number
  service_id: number
  price: number // THIS IS CENTS
}

export interface APILaborTierServiceUpdate {
  id: number
  tier_id: number
  service_id: number
  price: number // THIS IS CENTS
}

// labor tiers
//
export interface APILaborTier {
  id: number
  name: string
}

export interface APILaborTierCreate {
  name: string
}

export interface APILaborTierUpdate {
  id: number
  name: string
}

// labor services
//
export interface APILaborService {
  id: number
  name: string
  type: string // 'service' | 'hourly'
  category: string
}

export interface APILaborServiceCreate {
  name: string
  type: string
  category: string
}

export interface APILaborServiceUpdate {
  id: number
  name: string
  type: string
  category: string
}

// user labor tiers
//
export interface APIUserLaborTier {
  id?: number
  user_id: number
  tier_id: number
  tier_name?: string // appears on the response not on the request
}

// internal interfaces
//
//

export interface LaborService {
  id: number
  name: string
  type: string // 'service' | 'hourly'
  category: string // tags that help us organize the services visually
}

export interface LaborTier {
  id: number
  name: string
}

export interface UserLaborTier {
  userId: number
  id: number
  name: string
}

export interface LaborTierService {
  id: number
  priceDollars: number
  tierId: number
  serviceId: number
  category: string
}

export interface LaborItem {
  id: number
  priceDollars: number
  tier: LaborTier
  service: LaborService
}

export const LABOR_SERVICE_TYPES = {
  service: 'service',
  hourly: 'hourly',
}

export interface LaborServiceOption {
  id: number
  label: string
}

