/////////////// mappers.ts
//
//

import { map } from 'lodash'
import {
  APIBowlType,
  APIBowlTypeLara,
  APISessionColorLara,
  APISessionExtensionLara,
  APISessionMetaLara,
  APISessionPhotoLara,
  APISessionSupplyLara,
  BowlType,
  BowlTypeLara,
  SessionColorLara,
  SessionExtensionLara,
  SessionMetaLara,
  SessionPhotoLara,
  SessionSupplyLara,
} from './interfaces'
import { mapAPIColorLaraToColorLara } from '../products/mappers'
import { buildDateFromServer } from "../../core/dates";


export const mapAPIBowlTypeToBowlType = ( api: APIBowlType ): BowlType => {
  return {
    bowltypeId: api.bowltype_id,
    id: api.id,
    name: api.name,
  }
}
export const mapAPIBowlTypesToBowlTypes = ( api: APIBowlType[] ): BowlType[] => {
  return map(api, mapAPIBowlTypeToBowlType)
}


// lara sessions
//
//
//
//
export const mapAPISessionLaraToSessionLara = ( api: APISessionMetaLara ): SessionMetaLara => {
  return {
    id: api.id,
    client: {
      id: api.client.id,
      firstName: api.client.first_name,
      lastName: api.client.last_name,
      middleName: api.client.middle_name,
      phone: api.client.phone,
      sessionCount: api.client.session_count,
      note: api.client.note,
    },

    date: buildDateFromServer(api.date),
    dateInTz: api.date_in_tz,
    notes: api.notes,
    user: {
      id: api.user.id,
      userId: api.user.user_id,
      fullName: api.user.full_name,
      firstName: api.user.first_name,
      lastName: api.user.last_name,
      photoUrl: api.user.user_photo_url || '',
    },
    assistant: api.assistant ? {
      id: api.assistant.id,
      userId: api.assistant.user_id,
      fullName: api.assistant.full_name,
      firstName: api.assistant.first_name,
      lastName: api.assistant.last_name,
      photoUrl: api.user.user_photo_url || '',
    } : null,
    status: api.status,
    completedDate: api.completed_date,
    completedDateInTz: api.completed_date_in_tz,
    totalClientPurchasePriceSessionColorItemsCents: api.total_client_color_items_cents,
    totalClientPurchasePriceSessionExtensionItemsCents: api.total_client_purchase_price_session_extension_items,
    totalClientPurchasePriceSessionLaborItemsCents: api.total_client_purchase_price_session_labor_items,
    totalClientPurchasePriceSessionSupplyCents: api.total_client_purchase_price_session_supply,
    totalClientPurchasePriceCents: api.total_client_purchase_price_cents,
    totalColorItemsWasteCents: api.total_color_items_waste_cents,
  };
};
export const mapAPISessionsLaraToSessionsLara = ( api: APISessionMetaLara[] ): SessionMetaLara[] => {
  return map(api, mapAPISessionLaraToSessionLara)
}
export const mapAPISessionSupplyLaraToSessionSupplyLara = ( api: APISessionSupplyLara ): SessionSupplyLara => {
  return {
    id: api.id,
    name: api.name,
    priceCents: api.price,
    quantity: api.quantity,
    supply: {
      id: api.supply.id,
      name: api.supply.name,
      priceCents: api.supply.price
    }
  }
}
export const mapAPISessionSuppliesLaraToSessionSuppliesLara = ( api: APISessionSupplyLara[] ): SessionSupplyLara[] => {
  return map(api, mapAPISessionSupplyLaraToSessionSupplyLara)
}

export const mapAPISessionExtensionLaraToSessionExtensionLara = ( api: APISessionExtensionLara ): SessionExtensionLara => {
  return {
    id: api.id,
    sessionId: api.session_id,
    product: mapAPIColorLaraToColorLara(api.product),
    salonPurchasePrice: api.salon_purchase_price_per_unit,
    clientPurchasePrice: api.client_purchase_price_per_unit,
    quantity: api.quantity ? parseFloat(api.quantity) : 0,
  }
}
export const mapAPISessionExtensionsLaraToSessionExtensionsLara = ( api: APISessionExtensionLara[] ): SessionExtensionLara[] => {
  return map(api, mapAPISessionExtensionLaraToSessionExtensionLara)
}

export const mapAPIBowlTypeLaraToBowlTypeLara = ( api: APIBowlTypeLara ): BowlTypeLara => {
  return {
    bowltypeId: api.bowltype_id,
    id: api.id,
    name: api.name,
  }
}
export const mapAPISessionColorLaraToSessionColorLara = ( api: APISessionColorLara ): SessionColorLara => {
  return {
    id: api.id,
    sessionId: api.session_id,
    product: mapAPIColorLaraToColorLara(api.product),
    clientTotalPriceCents: api.client_total_cents,
    wasteTotalPriceCents: api.waste_total_cents,
    salonPurchasePrice: api.salon_purchase_price_per_gram,
    clientPurchasePrice: api.client_purchase_price_per_gram,
    bowlType: api.bowl_type ? mapAPIBowlTypeLaraToBowlTypeLara(api.bowl_type) : null,
    bowlNickname: api.bowl_nickname,
    amountGrams: api.amount_grams ? parseFloat(api.amount_grams) : 0,
    targetAmountGrams: api.target_amount_grams ? parseFloat(api.target_amount_grams) : null,
    wasteGrams: api.waste_grams ? parseFloat(api.waste_grams) : null,
    bowl: api.bowl,
    methodOfEntry: api.method_of_entry,
    displayUnit: api.display_unit,

  }
}
export const mapAPISessionColorsLaraToSessionColorsLara = ( api: APISessionColorLara[] ): SessionColorLara[] => {
  return map(api, mapAPISessionColorLaraToSessionColorLara)
}

export const mapAPISessionPhotoLaraToSessionPhotoLara = ( api: APISessionPhotoLara ): SessionPhotoLara => {
  return {
    id: api.id,
    clientId: api.client_id,
    sessionId: api.session_id,
    sessionDate: api.session_date,
    photoUrl: api.photo_url,
    photoTag: api.photo_tag,
    storageObject: api.storage_object,
  }
}
export const mapAPISessionPhotosLaraToSessionPhotosLara = ( api: APISessionPhotoLara[] ): SessionPhotoLara[] => {
  return map(api, mapAPISessionPhotoLaraToSessionPhotoLara)
}
