import { PageHeader } from "../../mini-lib/page-header/PageHeader";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { ConfirmPopover } from "../../mini-lib/confirm-popover/ConfirmPopover";
import { useDispatch } from "react-redux";
import { dispatchDeleteDemoData } from "../../data/admin-data/api";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { COLORS } from "../../mini-lib/theme/colors";
import { Gap } from "../../mini-lib/gap/Gap";

const deletableSalons = [
  {
    email: 'demo+webinar@salonscale.com',
    salonId: '11848',
    userId: 23512,
  }, {
    email: 'demo+qa1@salonscale.com',
    salonId: '11948',
    userId: 23914,
  }, {
    email: 'demo+qa2@salonscale.com',
    salonId: '11949', // note the django validator expects a string
    userId: 23916,
  },
  // {
  //   email: 'staging test salon',
  //   salonId: '5536', // note the django validator expects a string
  //   userId: 9202,
  // },
]

export const AdminDataResetPage = () => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  const deleteDataClicked = (params: {salonId: string, userId: number}) => {
    dispatch(dispatchDeleteDemoData({ token: user?.token, salonId: params.salonId, userId: params.userId, isDryRun: false }))
  }
  return (
    <Box maxW="1200px" margin="auto">
      <PageHeader title="Admin Delete Data" breadcrumbs={[]}/>
      <Box h="24px"/>
      <Text variant='title3'>
        A place to manually reset data for internal salons.
      </Text>
      <Text variant='footnote'>
        Please ensure no one else is using the account before you reset the data!
      </Text>
      <Box h="24px"/>
      <Flex gridGap='12px' wrap='wrap'>
        {deletableSalons.map(salon => {
          return (
            <DeleteSalonDataCard key={salon.salonId} email={salon.email} userId={salon.userId} salonId={salon.salonId} deleteDataClicked={deleteDataClicked}/>
          )
        })}
      </Flex>
    </Box>
  )
}

export const DeleteSalonDataCard = ( props: {
  email: string
  salonId: string
  userId: number
  deleteDataClicked: ( params: {salonId: string, userId: number} ) => void
} ) => {
  const { salonId, userId, email, deleteDataClicked } = props
  return (
    <Flex border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius='15px' p='24px' maxW='300px' direction='column'
          align='center' justify='center' w='300px'>
      <Text variant='title3mobile'>{email}</Text>
      <Gap/>
      <ConfirmPopover title={`This action can't be reversed`} subtitle={'Are you sure?'}
                      onConfirm={() => deleteDataClicked({salonId, userId})}>
        <Button variant='round'>
          Reset Data
        </Button>
      </ConfirmPopover>
    </Flex>
  )
}
