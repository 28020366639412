import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  Spinner,
  Text,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody, Switch,
} from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import {
  reduceEditableLaborCategory,
  reduceSelectedLaborCategory,
  selectAllLaborCategories, selectEditableLaborCategory,
  selectLaborItemList,
  selectLaborServiceList,
  selectLaborServicesByCategory,
  selectLaborTierList,
  selectSelectedLaborCategory,
} from '../../data/labor/slice'
import {
  dispatchCreateLaborTiers,
  dispatchDeleteLaborTiers,
  dispatchCreateLaborServices,
  dispatchDeleteLaborCategory,
  dispatchListLaborItems,
  dispatchListLaborServices,
  dispatchUpdateLaborServices,
  dispatchListLaborTiers,
  dispatchUpdateLaborTiers,
} from '../../data/labor/api'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { LaborTable } from './LaborTable'
import { dispatchListSalonUserTiers } from '../../data/user/api'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ROUTES } from '../../appRoutes'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Gap } from '../../mini-lib/gap/Gap'
import {
  ALL_LABOR_CATEGORIES,
  DEFAULT_TIER_NAME,
  soloLaborOnboardSteps,
  teamLaborOnboardSteps
} from '../../data/labor/constants'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../mini-lib/theme/colors'
import { UseViewSize } from '../../core/UseViewSize'
import { SelectInput, TextInput } from '../../mini-lib'
import { APILaborServiceCreate, APILaborServiceUpdate, APILaborTierCreate, APILaborTierUpdate, LaborTier } from '../../data/labor/interfaces'
import { SearchFilter, ConfirmPopover } from '../../mini-lib'
import { sortTiers } from "../../data/labor/utils";
import { dispatchListSalonUsers } from "../../data/salon-user/api";
import { HelpPopover } from "../start-guide/common-components/HelpPopover";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";

export const LaborPage = () => {
  const {
    user,
    salonId,
  } = UseBaseApiParams()
  const salonType = user.currentSalonContext?.salonType
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Labors' }]
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams: any = UseQueryParams()
  const empty = queryParams.get('empty')
  const showTable = queryParams.get('showTable')
  const items = useAppSelector(selectLaborItemList)
  const tiers = useAppSelector(selectLaborTierList)
  const services = useAppSelector(selectLaborServiceList)

  const itemsLoaded = !!items
  const tiersLoaded = !!tiers
  const servicesLoaded = !!services

  const editableLaborCategory = useAppSelector(selectEditableLaborCategory)
  const isEditCategoryModalVisible = editableLaborCategory !== ''

  const [isCreateMenuVisible, setIsCreateMenuVisible] = useState(false)

  useEffect(() => {
    dispatch(dispatchListSalonUserTiers({token: user.token, salonId}))
  }, [dispatch, user.token, salonId])

  useEffect(() => {
    if (!itemsLoaded) {
      dispatch(dispatchListLaborItems({token: user.token, salonId}))
    }
  }, [dispatch, itemsLoaded, user.token, salonId])

  useEffect(() => {
    if (!tiersLoaded) {
      dispatch(dispatchListLaborTiers({token: user.token, salonId}))
    }
  }, [dispatch, tiersLoaded, user.token, salonId])

  useEffect(() => {
    if (!servicesLoaded) {
      dispatch(dispatchListLaborServices({token: user.token, salonId}))
    }
  }, [dispatch, servicesLoaded, user.token, salonId])

  useEffect(() => {
    dispatch(dispatchListSalonUsers({token: user.token, salonId}))
  }, [dispatch, user.token, salonId])

  const goToOnboard = () => {
    history.push(generatePath(ROUTES.laborOnboard, { salonId }))
  }

  // 1. make sure all data is loaded
  // 2. make data at least one type of data exists
  // 3. if show empty exists always show empty
  // 4. if show table exists always show the table
  const show = !empty && tiers && services && items && (tiers.length > 0 || services.length > 0 || showTable)
  const showEmpty = empty || (tiers && services && tiers.length === 0 && services.length === 0)
  const { isMobile } = UseViewSize()
  // todo: get this from selector
  const allCategories = useAppSelector(selectAllLaborCategories)
  const hasMultipleTiers = (tiersLoaded && tiers?.length > 1)

  // when hasMultiple tiers changes ensure we update allowMultipleTiers
  // note: this avoids a race condition
  useEffect(() => {
    setAllowMultipleTiers(hasMultipleTiers)
  }, [hasMultipleTiers]);

  const [allowMultipleTiers, setAllowMultipleTiers] = useState(hasMultipleTiers)
  const [isTierModalVisible, setIsTierModalVisible] = useState(false)
  const [isTurnOffTiersModalVisible, setIsTurnOffTiersModalVisible] = useState(false)

  const createMenuIndex = salonType === 'salon' ? teamLaborOnboardSteps.createMenu : soloLaborOnboardSteps.createMenu
  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title="Services"
          breadcrumbs={breadcrumbs}
          showHelp={false}
          actions={
            <Flex align="center" gridGap="12px" wrap='wrap'>
              {tiersLoaded && (
                <>
                  <Text variant='round-ghost' color={COLORS.midnight_900}>
                    Use Level System
                  </Text>
                  <Switch
                    isChecked={allowMultipleTiers}
                    size="lg"
                    colorScheme='brand.skylight'
                    onChange={() => {
                      if (allowMultipleTiers) {
                        setIsTurnOffTiersModalVisible(true)
                      } else {
                        setAllowMultipleTiers(!allowMultipleTiers)
                      }
                    }}
                  />
                </>
              )}
              {allowMultipleTiers && (
                <HelpPopover description={'Manage your stylist labor levels here'} stepIndex={teamLaborOnboardSteps.manageLevels} guideTypes={[CHECKLIST_CODES.addLaborTeam]}>
                  <Button variant="round-outline" colorScheme="brand.midnight" onClick={() => setIsTierModalVisible(true)}>
                    Manage Levels
                  </Button>
                </HelpPopover>
              )}
              <HelpPopover description={'Create new service menus here'} stepIndex={createMenuIndex} guideTypes={[CHECKLIST_CODES.addLaborSolo, CHECKLIST_CODES.addLaborTeam]}>
                <Button variant="round" colorScheme="brand.midnight" onClick={() => setIsCreateMenuVisible(true)}>
                  Create New Menu
                </Button>
              </HelpPopover>
            </Flex>
          }
        />
      }
      content={
        <>
          <Gap/>
          {!isMobile && <Gap/>}
          {isEditCategoryModalVisible && (<EditMenuModal showModal={isEditCategoryModalVisible}/>)}
          {isCreateMenuVisible && (
            <CreateMenuModal showModal={isCreateMenuVisible} setShowModal={setIsCreateMenuVisible} />
          )}
          {isTierModalVisible && (
            <TierModal showModal={isTierModalVisible} setShowModal={setIsTierModalVisible} />
          )}
          {isTurnOffTiersModalVisible && (
            <TurnOffTiersModal showModal={isTurnOffTiersModalVisible} setShowModal={setIsTurnOffTiersModalVisible} />
          )}
          {/* table */}
          {show && (
            <Flex w="100%" gridGap="24px" h={isMobile ? '100%' : "100vh"} wrap="wrap">
              <Flex w={isMobile ? '100%' : '240px'} justify={isMobile ? 'center' : 'start'}>
                <CategoryMenuList categories={allCategories} />
              </Flex>
              <Box flex="1" overflowX="scroll">
                <LaborTable laborItems={items ? items : []} laborTiers={tiers || []} laborServices={services || []} />
              </Box>
            </Flex>
          )}

          {/* empty state */}
          {showEmpty && (
            <>
              <Gap s="48px" />
              <EmptyBox
                title="NO LABOR PRICES SET"
                content="Now you can account for your time with SalonScale’s Labor Feature. No matter how you charge for your time, we
          will make sure you charge your worth. Click below to get started."
              >
                <Box h="24px" />
                <Button variant="round" colorScheme="brand.midnight" onClick={goToOnboard}>
                  LET'S GO!
                </Button>
              </EmptyBox>
            </>
          )}

          {/* loading state */}
          {(!tiers || !services) && (
            <Flex h="500px" align="center" justify="center">
              <Spinner />
            </Flex>
          )}
        </>
      }
    />
  )
}
export const CategoryMenuList = (props: { categories: string[] }) => {
  const { categories } = props
  const [searchText, setSearchText] = useState('')
  const filteredCategories = categories.filter(category => category.includes(searchText))
  return (
    <Box w="240px" minW="240px">
      <Text variant="default/regular/title3">Menus</Text>

      <Gap />
      <SearchFilter
        width="100%"
        theme="midnight"
        placeholder="Search"
        onChange={(text) => {
          setSearchText(text)
        }}
        value={searchText}
        onClear={() => setSearchText('')}
      />
      <Gap />
      <CategoryOption category={ALL_LABOR_CATEGORIES} showMenu={false} index={-1}/>

      {filteredCategories.map((category, index) => {
        return <CategoryOption key={category} category={category} index={index}/>
      })}
    </Box>
  )
}

const CategoryOption = (props: { category: string; showMenu?: boolean, index: number }) => {
  const { category, showMenu = true, index } = props
  const {user} = UseBaseApiParams()
  const salonType = user.currentSalonContext?.salonType
  const dispatch = useDispatch()
  const selectedCategory = useAppSelector(selectSelectedLaborCategory)
  const isSelected = selectedCategory === category
  const filterMenuHelpIndex = index === 0
    ? salonType === 'salon'
      ? teamLaborOnboardSteps.filterMenu
      : soloLaborOnboardSteps.filterMenu
    : -1
  const deleteMenuHelpIndex = index === 0
    ? salonType === 'salon'
      ? teamLaborOnboardSteps.deleteMenu
      : soloLaborOnboardSteps.deleteMenu
    : -1
  return (
    <HelpPopover description={'Filter by menu type in this side navigation or search for specific service items using the search bar.'} stepIndex={filterMenuHelpIndex} guideTypes={[CHECKLIST_CODES.addLaborSolo, CHECKLIST_CODES.addLaborTeam]}>
      <Flex
        cursor="pointer"
        align="center"
        justify="space-between"
        h="40px"
        borderRadius="50px"
        p="12px"
        bg={isSelected ? COLORS.skylight_50 : ''}
        color={isSelected ? COLORS.skylight_500 : ''}
      >
        <Text flex="1" onClick={() => dispatch(reduceSelectedLaborCategory(category))}>
          {category}
        </Text>
        {showMenu && (
          <HelpPopover description='Click the three dots to edit or delete the menu.' stepIndex={deleteMenuHelpIndex} guideTypes={[CHECKLIST_CODES.addLaborSolo, CHECKLIST_CODES.addLaborTeam]}>
            <Box>
              <CategoryMenu category={category} />
            </Box>
          </HelpPopover>
        )}
      </Flex>
    </HelpPopover>
  )
}

export const CategoryMenu = (props: { category: string }) => {
  const dispatch = useDispatch()
  const { category } = props
  const { user, salonId } = UseBaseApiParams()
  return (
    <Menu>
      <MenuButton>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => {
            dispatch(reduceEditableLaborCategory(category))
          }}
          icon={<MaterialIcon name="edit" />}
        >
          Edit Menu
        </MenuItem>
        <MenuItem
          color="danger"
          onClick={() => {
            dispatch(
              dispatchDeleteLaborCategory({
                token: user.token,
                salonId,
                userId: user.userId,
                category
              }),
            )
          }}
          icon={<MaterialIcon name="delete" />}
        >
          Delete Menu
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export const CreateMenuModal = (props: { showModal: boolean; setShowModal: (show: boolean) => void }) => {
  const { showModal, setShowModal } = props
  const [categoryTitle, setCategoryTitle] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const onCancel = () => {
    setShowModal(false)
  }
  const onConfirm = () => {
    setShowModal(false)
    const model: APILaborServiceCreate = {
      name: categoryTitle + ' service',
      type: selectedType,
      category: categoryTitle,
    }
    dispatch(
      dispatchCreateLaborServices({
        token: user.token,
        userId: user.userId,
        salonId: salonId,
        models: [model],
      }),
    )
  }
  return (
    <>
      {showModal && (
        <Modal onClose={onCancel} isOpen={showModal} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Flex p="36px 0 24px" direction="column">
                <Box textAlign="center" fontSize="20px" fontWeight="bold">
                  New Menu Create
                </Box>

                <Gap />

                <TextInput
                  placeholder="enter a title"
                  label="Menu Title"
                  value={categoryTitle}
                  errorText={''}
                  onBlur={(val) => {}}
                  onChange={setCategoryTitle}
                />

                <Gap />

                <SelectInput
                  placeholder="select an option"
                  label="Default Charge Type (this can be adjusted later)"
                  options={[
                    { label: 'Hourly Rate', value: 'hourly' },
                    { label: 'Flat Fee', value: 'service' },
                  ]}
                  value={selectedType}
                  errorText={''}
                  onBlur={(val) => {}}
                  onChange={setSelectedType}
                />

                <Gap />

                <Flex justify="end" gridGap="12px">
                  <Button colorScheme="brand.midnight" variant="round-ghost-upper" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    disabled={selectedType === '' || categoryTitle === ''}
                    colorScheme="brand.midnight"
                    variant="round"
                    onClick={() => {
                      onConfirm()
                    }}
                  >
                    Create
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}



export const EditMenuModal = (props: { showModal: boolean; }) => {
  const {  showModal } = props
  const [categoryTitle, setCategoryTitle] = useState('')
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const selectedCategory = useAppSelector(selectEditableLaborCategory)
  const laborServicesByCategory = useAppSelector(selectLaborServicesByCategory)

  const onCancel = () => {
    dispatch(reduceEditableLaborCategory(''))
  }
  const onConfirm = () => {
    const laborServicesForCategory = laborServicesByCategory ? laborServicesByCategory[selectedCategory] : []
    const models: APILaborServiceUpdate[] = laborServicesForCategory.map(laborService => {
      return {
        id: laborService.id,
        name: laborService.name,
        type: laborService.type,
        category: categoryTitle,
      }
    })
    dispatch(
      dispatchUpdateLaborServices({
        token: user.token,
        userId: user.userId,
        salonId: salonId,
        models: models,
      }),
    )
    dispatch(reduceEditableLaborCategory(''))
  }
  return (
    <>
      {showModal && (
        <Modal onClose={onCancel} isOpen={showModal} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Flex p="36px 0 24px" direction="column">
                <Box textAlign="center" fontSize="20px" fontWeight="bold">
                  Menu Update
                </Box>

                <Gap />

                <TextInput
                  placeholder="enter a title"
                  label="Menu Title"
                  value={categoryTitle}
                  errorText={''}
                  onBlur={(val) => {}}
                  onChange={setCategoryTitle}
                />

                <Gap />

                <Flex justify="end" gridGap="12px">
                  <Button colorScheme="brand.midnight" variant="round-ghost-upper" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    disabled={categoryTitle === ''}
                    colorScheme="brand.midnight"
                    variant="round"
                    onClick={() => {
                      onConfirm()
                    }}
                  >
                    Update
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}


export const TierModal = (props: { showModal: boolean; setShowModal: (show: boolean) => void }) => {
  const { showModal, setShowModal } = props

  const onCancel = () => {
    setShowModal(false)
  }


  return (
    <>
      {showModal && (
        <Modal onClose={onCancel} isOpen={showModal} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Gap/>
              <Box textAlign="center" fontSize="20px" fontWeight="bold">
                Manage Your Levels System
              </Box>
              <Gap/>
              <ManageTiers onBack={onCancel}/>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export const ManageTiers = (props: { onBack: () => void, onContinue?: () => void }) => {
  const tiers = useAppSelector(selectLaborTierList)
  const tiersLoaded = !!tiers
  const {onBack, onContinue} = props
  return (
    <Flex direction="column">

      {tiersLoaded &&
        tiers &&
        sortTiers(tiers).map((tier) => {
          return (
            <Box key={tier.id}>
              <TierRow tier={tier} />
              <Gap />
            </Box>
          )
      })}

      <Gap />

      <Flex justify="end" gridGap="12px">
        <Button
          colorScheme="brand.midnight"
          variant="round-outline"
          onClick={() => {
            onBack()
          }}
        >
          Back
        </Button>
        {onContinue && (
          <Button
            colorScheme="brand.midnight"
            variant="round"
            onClick={() => {
              onContinue()
            }}
          >
            Continue
          </Button>
        )}
        <CreateLaborTierButton />
      </Flex>
    </Flex>
  )
}
export const TurnOffTiersModal = (props: { showModal: boolean; setShowModal: (show: boolean) => void }) => {
  const tiers = useAppSelector(selectLaborTierList)
  const tiersLoaded = !!tiers

  const { showModal, setShowModal } = props

  const onCancel = () => {
    setShowModal(false)
  }


  return (
    <>
      {showModal && (
        <Modal onClose={onCancel} isOpen={showModal} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Flex p="36px 0 24px" direction="column">
                <Box textAlign="center" fontSize="20px" fontWeight="bold">
                  Please Select Which Levels to Delete Before You Turn Off Levels
                </Box>

                <Gap />

               {tiersLoaded && tiers && sortTiers(tiers).map(tier => {
                  return (
                    <Box key={tier.id}>
                      <TierRow tier={tier} />
                      <Gap/>
                    </Box>
                  )
                })}

                <Gap />

                <Flex justify="end" gridGap="12px">
                  <Button
                    colorScheme="brand.midnight"
                    variant="round-outline"
                    onClick={() => {
                      onCancel()
                    }}
                  >
                    Back
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

const TierRow = (props: {tier: LaborTier}) => {
  const {tier} = props
  const {user, salonId} = UseBaseApiParams()
  const dispatch = useDispatch()
  const [name, setName] = useState(tier.name)
  const updateTier = () => {
    const model: APILaborTierUpdate = {
      id: tier.id,
      name: name,
    }
    dispatch(
      dispatchUpdateLaborTiers({
        token: user.token,
        userId: user.userId,
        salonId: salonId,
        models: [model],
      }),
    )
  }
  const deleteTier = () => {
    dispatch(
      dispatchDeleteLaborTiers({
        token: user.token,
        userId: user.userId,
        salonId: salonId,
        models: [tier],
      }),
    )
  }
  return (
    <Flex gridGap='12px' align='center' justify='center'>
      <TextInput
        variant='material'
        placeholder="enter a name"
        label="Level Name"
        value={name}
        errorText={''}
        onBlur={updateTier}
        onChange={setName}
      />
      <ConfirmPopover
          title={`This Action is Permanent`}
          subtitle={`This will delete the tier`}
          onConfirm={deleteTier}
        >
      <Button variant='round-ghost' colorScheme='brand.midnight'>Delete</Button>
      </ConfirmPopover>
    </Flex>
  )
}


export const CreateLaborTierButton = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const addTier = () => {
    const tier: APILaborTierCreate = { name: DEFAULT_TIER_NAME }
    dispatch(
      dispatchCreateLaborTiers({
        token: user.token,
        userId: user.userId,
        salonId: salonId,
        models: [tier],
      }),
    )
  }
  return (
    <Button onClick={addTier} variant='round' colorScheme='brand.midnight'>Add Level</Button>
  )
}