import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import React, { useEffect, useState } from 'react'
import { useHistory, generatePath, useLocation } from 'react-router-dom'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { Box, Button, Divider, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { maybePluralize } from '../../core/text'
import { Gap } from '../../mini-lib/gap/Gap'
import { COLORS } from '../../mini-lib/theme/colors'
import { dispatchBulkCreateUserLara, dispatchListUsersMetaLara } from '../../data/user/api'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectUsersMeta } from '../../data/user/slice'
import { assign, find, values } from 'lodash'
import { Loading } from '../../mini-lib/loading/Loading'
import { SquareMember } from '../../data/integrations/interfaces'
import { dispatchListSquareMembers } from '../../data/integrations/api'
import {
  reduceRemoveSquareMember,
  reduceUpdateSquareMember,
  selectSquareMembersById,
} from '../../data/integrations/slice'
import { SelectInput, TextInput } from '../../mini-lib'
import { ROLE_OPTIONS } from '../../data/salon/constants'
import { UseViewSize } from '../../core/UseViewSize'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { mapSquareMembersToAPIBulkCreateUsersLara } from "../../data/integrations/mappers";
import { ROUTES } from "../../appRoutes";
import { HELP_DRAWER_STATES } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { reduceSetStepCompleteDrawerState } from '../../data/start-guide/slice'

export const SquareConnectTeamPage = () => {
  const breadcrumbs = []
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(dispatchListUsersMetaLara({ token: user?.token, salonId }))
  }, [dispatch, salonId, user?.token])

  useEffect(() => {
    dispatch(dispatchListSquareMembers({ token: user?.token, salonId }))
  }, [dispatch, salonId, user?.token])

  const squareMembersById = useAppSelector(selectSquareMembersById)
  const salonMembers = useAppSelector(selectUsersMeta)
  // go through the integrations members and see if there is an existing member that matches them
  const squareMembers = squareMembersById ? values(squareMembersById).map((member) => {
    const existingMember = find(salonMembers, { email: member.email })
    if (existingMember) {
      return assign({}, member, { existingUserMeta: existingMember })
    }
    return member
  }) : null
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Connect Team" breadcrumbs={breadcrumbs} />}
      content={
        <Box>
          <Gap />
          {squareMembers && squareMembers.length > 0 && (
            <Text variant="callout-desktop-regular" textAlign="center">
              We found {squareMembers.length} team {maybePluralize('member', squareMembers.length)} in Square. Please
              review their information and set their permission level before completing.
            </Text>
          )}
          <Gap />
          {salonMembers && squareMembers ? <SquareMembersList squareMembers={squareMembers} /> : <Loading p="48px" />}
          {salonMembers && squareMembers && <Actions members={squareMembers} />}
        </Box>
      }
    />
  )
}

export const SquareMembersList = (props: { squareMembers: SquareMember[] }) => {
  const { squareMembers } = props
  const logoUrl = require('../../assets/square-logo.png').default
  return (
    <Box border={`1px solid ${COLORS.shades_neutral_200}`} borderRadius="8px">
      <Flex gridGap="8px" p="12px" fontWeight="bold" align="center">
        <img src={logoUrl} alt="square logo" style={{ height: '32px', width: '32px' }} />
        Square
      </Flex>
      <Divider />
      {squareMembers && squareMembers.length === 0 && (
        <EmptyBox
          h="200px"
          title="No Team Members found in Square"
          content="Please ensure you have team members set up in Square before you try importing them!"
        ></EmptyBox>
      )}
      {squareMembers && squareMembers.length > 0 && (
        <Flex overflowX="scroll">
          <TableContainer>
            <Table>
              <Thead>
                <SquareMemberHeaders />
              </Thead>
              <Tbody>
                {squareMembers.map((member) => {
                  return <SquareMemberRow key={member.squareId} member={member} />
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      )}
    </Box>
  )
}

export const SquareMemberHeaders = () => {
  const { isMobile } = UseViewSize()
  return (
    <Tr>
      {/*<Th></Th>*/}
      <Th>
        <Text minW="120px" textAlign="left" variant="callout-desktop-regular" textTransform="capitalize">
          {isMobile ? 'first' : 'first name'}
        </Text>
      </Th>
      <Th>
        <Text minW="120px" textAlign="left" variant="callout-desktop-regular" textTransform="capitalize">
          {isMobile ? 'last' : 'last name'}
        </Text>
      </Th>
      <Th>
        <Text minW="120px" textAlign="left" variant="callout-desktop-regular" textTransform="capitalize">
          email
        </Text>
      </Th>
      <Th>
        <Text minW="120px" textAlign="left" variant="callout-desktop-regular" textTransform="capitalize">
          phone
        </Text>
      </Th>
      <Th>
        <Text minW="120px" variant="callout-desktop-regular" textTransform="capitalize">
          {isMobile ? 'permission' : 'permission level'}
        </Text>
      </Th>
      <Th></Th>
    </Tr>
  )
}

const SquareMemberRow = (props: { member: SquareMember }) => {
  const dispatch = useDispatch()
  const {
    member,
    member: { squareId, first, last, email, phone, permissionLevel },
  } = props
  const variant: 'edit' | 'view' | any = 'edit'

  // note: local member stuff is here to increase the responsiveness of the inputs, trying to keep inputs in sync with the state in a fast way is hard
  const [localMember, setLocalMember] = useState<SquareMember>(member)
  const updateRow = (newValues: Partial<SquareMember>) => {
    setLocalMember((prevState) => ({
      ...prevState,
      ...newValues,
    }))
  }
  const saveRowToState = (newValues: Partial<SquareMember>) => {
    const payload = { id: squareId, properties: assign({}, localMember, newValues) }
    dispatch(reduceUpdateSquareMember(payload))
  }
  return (
    <Tr bg={localMember.existingUserMeta ? COLORS.lavender_50 : !!localMember.email ? 'none' : COLORS.peach_50}>
      {/*<Td>*/}
        {/*<TextBadge label={existingUserMeta ? 'sync' : 'add'} cHex={COLORS.lavender_500} bgHex={COLORS.lavender_100} />*/}
      {/*</Td>*/}
      <Td>
        <>
          {variant === 'edit' && (
            <TextInput
              theme="lavender"
              variant="material"
              label=""
              value={localMember.first}
              onChange={(val) => updateRow({ first: val })}
              onBlur={(val) => saveRowToState({ first: val })}
            />
          )}
          {variant === 'view' && <Text variant="callout">{first}</Text>}
        </>
      </Td>
      <Td>
        <>
          {variant === 'edit' && (
            <TextInput
              theme="lavender"
              variant="material"
              label=""
              value={localMember.last}
              onChange={(val) => updateRow({ last: val })}
              onBlur={(val) => saveRowToState({ last: val })}
            />
          )}
          {variant === 'view' && <Text variant="callout">{last}</Text>}
        </>
      </Td>
      <Td>
        <>
          {variant === 'edit' && (
            <TextInput
              theme="lavender"
              variant="material"
              label=""
              value={localMember.email}
              onChange={(val) => updateRow({ email: val })}
              onBlur={(val) => saveRowToState({ email: val })}
            />
          )}
          {variant === 'view' && (
            <Text variant="callout" maxW="200px" isTruncated={true}>
              {email}
            </Text>
          )}
        </>
      </Td>
      <Td>
        <>
          {variant === 'edit' && (
            <TextInput
              theme="lavender"
              variant="material"
              label=""
              value={localMember.phone}
              onChange={(val) => updateRow({ phone: val })}
              onBlur={(val) => saveRowToState({ phone: val })}
            />
          )}
          {variant === 'view' && <Text variant="callout">{phone}</Text>}
        </>
      </Td>
      <Td>
        <>
          {variant === 'edit' && (
            <SelectInput
              options={ROLE_OPTIONS.map((option) => {
                return { label: option, value: option }
              })}
              theme="lavender"
              variant="material"
              label=""
              value={localMember.permissionLevel}
              onBlur={() => {}}
              onChange={(val) => {
                updateRow({ permissionLevel: val })
                saveRowToState({ permissionLevel: val })
              }}
              isClearable={false}
              isSearchable={false}
            />
          )}
          {variant === 'view' && <Text variant="callout">{permissionLevel}</Text>}
        </>
      </Td>

      <Td>
        <>
          <Button variant="round-ghost" onClick={() => dispatch(reduceRemoveSquareMember(squareId))}>
            Remove
          </Button>
        </>
      </Td>
    </Tr>
  )
}

export const Actions = (props: { members: SquareMember[] }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const { members } = props
  const { salonId, user } = UseBaseApiParams()
  const membersWithEmails = members.filter(member => !!member.email)
  const membersWithExistingUsers = membersWithEmails.filter((member) => !!member.existingUserMeta)
  const numWithExisting = membersWithExistingUsers.length
  const numWithoutExisting = membersWithEmails.length - numWithExisting
  const sendInvitations = async() => {
    const users = mapSquareMembersToAPIBulkCreateUsersLara(membersWithEmails)
    await dispatch(dispatchBulkCreateUserLara({token: user.token, users, salonId}))
    search === ""
      ? history.push(generatePath(ROUTES.integrationSettings, { salonId }))
      : history.push(generatePath(ROUTES.integrationSettings, { salonId }) + search);

    search !== "" && dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
  }
  return (
    <>
      <Gap />
      {(numWithoutExisting > 0 || numWithExisting > 0) && (
        <>
          <Text align='right' variant='callout-desktop-regular'>
            * new users will be sent an email to create their password, syncing existing users requires no further action
          </Text>
          {membersWithEmails.length < members.length && <Text align='right' variant='callout-desktop-regular'>
            <Text color={COLORS.danger}>* all fields are required</Text>
          </Text>}
          <Gap s='8px'/>
          <Flex justifyContent="flex-end">
            <Button variant="round" colorScheme="brand.midnight" onClick={sendInvitations}>
              {numWithExisting > 0 && (
                <>
                  Sync {numWithExisting} {maybePluralize('user', numWithExisting)}
                </>
              )}
              {numWithExisting > 0 && numWithoutExisting > 0 && ` & `}
              {numWithoutExisting > 0 && (
                <>
                  Send {numWithoutExisting} {maybePluralize('invitation', numWithoutExisting)}
                </>
              )}
            </Button>
          </Flex>
        </>
      )}
    </>
  )
}
