import {
  LABOR_SERVICE_TYPES,
  LaborItem,
  LaborService,
  LaborServiceOption,
  LaborTier
} from './interfaces'

export const buildMockLaborTier = (id = 1, name = 'Tier 1'): LaborTier => {
  return { id, name }
}

export const buildMockLaborService = (id = 1, name = 'Service 1', type = LABOR_SERVICE_TYPES.hourly, category = 'category 1'): LaborService => {
  return { id, name, type, category }
}

export const buildMockLaborItem = (id: number, tier: LaborTier, service: LaborService, price = 500): LaborItem => {
  return {
    id,
    priceDollars: price,
    tier,
    service,
  }
}

export const MOCK_CATEGORIES = ['styling services', 'color services', 'express']

export const ALL_LABOR_CATEGORIES = 'All Menus'

export const DEFAULT_TIER_NAME = 'New Level'
export const DEFAULT_SERVICE_NAME = 'New Service'

export const DEFAULT_CATEGORY_NAME = 'New Category'

export const initialServiceOptions: LaborServiceOption[] = [
  { id: 1, label: 'Single Process Color' },
  { id: 2, label: 'Double Process Color' },
  { id: 3, label: 'Highlights' },
  { id: 4, label: 'Hull Head Highlights' },
  { id: 5, label: 'Highlights with Base' },
  { id: 6, label: 'Treatment' },
  { id: 7, label: 'Blow out' },
  { id: 8, label: `Woman's Cut` },
  { id: 9, label: `Men's Cut` },
]

export const soloLaborOnboardSteps = {
  addService: 0,
  addPrice: 1,
  tierType: 2,
  createMenu: 3,
  filterMenu: 4,
  deleteMenu: 5
}

export const teamLaborOnboardSteps = {
  addService: 0,
  addPrice: 1,
  tierType: 2,
  createMenu: 3,
  filterMenu: 4,
  deleteMenu: 5,
  manageUserTiers: 6,
  manageLevels: 7,
}

export const LABOR_LOADING = {
  listServices: 'list-services',
  createServices: 'create-services',
  deleteServices: 'delete-services',
  updateServices: 'update-services',
  duplicateServices: 'duplicate-services',

  listTiers: 'list-tiers',
  updateTiers: 'update-tiers',
  createTiers: 'create-tiers',
  deleteTiers: 'delete-tiers',
  duplicateTiers: 'duplicate-tiers',

  listItems: 'list-labor-items',
  deleteItems: 'delete-items',
  updateItems: 'update-items',
  createItems: 'create-items',
}